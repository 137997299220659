export const environment = {
  name: 'Staging',
  version: '2.35.0.5767',
  production: true,
  sentry: {
    dsn: 'https://8a567cbe1759408a82f0600370df4e02@sentry.antony-it.de/24',
    environment: 'Staging'
  },
  mock: false,
  authentication: {
    tokenName: 'auth-token'
  }
};
